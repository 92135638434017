.color-inherit {
  color: inherit !important;
}

.ant-form-item-required {
  width: 100%;
}

.ant-form-horizontal .ant-form-item-label {
  display: flex;
}

.lh-2 {
  line-height: 2 !important;
}
.text-main {
  color: $primaryCol !important;
}

.ql-editor {
  text-align: right;
}

.ql-direction-rtl {
  margin-bottom: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type="number"] {
//     -moz-appearance: textfield;
//     text-align: right;
// }

.PhoneInputCountrySelectArrow {
  margin-right: 6px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.hide-lable {
  .ant-form-item-label {
    opacity: 0 !important;
  }
}

.grid-checkbox-group {
  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    @media (min-width: 1200px) {
      width: 23%;
    }
  }
}

.ant-layout {
  .ant-layout-sider {
    @media (max-width: 1200px) {
      z-index: 13;
      min-width: 200px;
      max-width: 300px !important;
      width: 250px !important;
    }
    @media (min-width: 1200px) {
      width: 300px !important;
      min-width: 250px !important;
      max-width: 300px !important;
    }
  }
}
.ant-menu-dark {
    li {
      .ant-menu-submenu-title {
        background-color: $primaryCol !important;
      }
    }
  }

.ant-layout-sider-children {
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.wrapper-content {
  padding: 0.5rem;
  background: #fff;
  border-radius: 12px;
  @media (min-width: 992px) {
    padding: 1.5rem;
  }
}
