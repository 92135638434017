body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iti, .iti__tel-input, .intl-tel-input {
  width: 100%;
  direction: ltr !important;
}

.iti .iti__country-container {
  left: 0 !important;
}

.iti .iti__tel-input {
  padding-left: 84px !important;
  padding-right: 0 !important;
}

.intl-tel-input .flag-container .selected-flag{
  display: flex !important;
    align-items: center !important;
  justify-content: center !important;
}

.intl-tel-input .flag-container .selected-flag .iti-flag {
  width: 20px !important;
  margin-right: 10px !important;
}
.intl-tel-input .flag-container .selected-flag .selected-dial-code {
  padding: 0 !important;
}

.full-width-label .ant-form-item-label, .full-width-label .ant-form-item-control {
  max-width: 100% !important;
}

.ql-tooltip{
  z-index: 100000;
}