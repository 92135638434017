.ant-menu {
  background: $primaryCol;
  height: 100%;
  .ant-menu-item {
    color: #9197b3;
    font-size: 14px;
    padding: 0 !important;
    font-weight: 600;
    letter-spacing: -0.14px;
    a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      padding: 0 14px;
      margin: 0 12px;
      border-radius: 8px;
      &.active {
        background: #fff;
        color: $primaryCol;
      }
    }
    background: transparent;
    .ant-menu-title-content {
      color: #fff;
    }
    &:hover {
      background: transparent !important;

      .ant-menu-title-content {
        color: #fff;
      }
    }
  }
}

.card-user-profile {
  width: 300px;
}
.ant-menu-submenu {
  .ant-menu-submenu-title {
    background-color: #dedede;
    &:hover {
      background-color: #dedede !important;
    }
  }
  .ant-menu-title-content {
    font-size: 18px;
    font-weight: bolder;
  }
}

.ant-menu-submenu-title {
  color: #fff;
  &:hover {
    background: transparent;
  }
}
.ant-menu-light .ant-menu-submenu-selected {
  //   background-color: #fff;
  border: 1px solid #fff;
  .ant-menu-submenu-title {
    background-color: #fff;
  }
  .ant-menu-inline {
    display: block !important;
    // background-color: red !important;
  }
}

.ant-menu-submenu-selected {
}
