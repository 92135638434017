/* FantasticForm.css */
.fantastic-form-container {
    background: #ffffff;
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

@media (max-width: 500) {
    .fantastic-form-container {
        padding: 10px !important;
    }
    .fantastic-form {
        padding: 10px !important;
    }
}

.form-title {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
}

.fantastic-form {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.form-input,
.form-textarea {
    border-radius: 8px;
}

.submit-button {
    width: 100%;
    background: #1890ff;
    border-color: #1890ff;
    border-radius: 8px;
    height: 40px;
    font-size: 16px;
}