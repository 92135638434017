/* Tables */

.ant-table-container {
  margin-bottom: 0;
  &.table-borderless {
    border: none;

    tr,
    td,
    th {
      border: none;
    }
  }
}

.ant-table-content {
  @media (min-width: 1200px) {
    max-height: 58vh;
    overflow: auto !important;
  }
}
.ant-pagination.ant-pagination-rtl {
  direction: ltr;
}

.ant-table-wrapper .ant-table.ant-table-middle .ant-table-title,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table.ant-table-middle tfoot > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle tfoot > tr > td {
  text-align: center;
}

.ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead > tr:nth-child(2) > th {
  padding-top: 0px;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td,
.ant-table {
  background-color: transparent !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  border: 3px solid #fff;
  border-top: 0;
  border-inline-start: 0;
}

.view-table {
  .ant-modal-content {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #323232;

    .first-col {
      border-left: 1px solid #eeeeee;
    }
  }

  .ant-modal-close {
    top: 9px;
    inset-inline-end: 7px;
  }
}

.btn-delete-table {
  button {
    background: #df0404 !important;
  }
}

.btn-report {
  background: #404b52;
  border-radius: 5px;
  padding: 10px 5px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  text-align: center;
}

.ant-table-wrapper {
  .ant-spin.ant-spin-spinning {
    background-color: #ffffff99;
  }
  .btn-edit{
    &:hover{
      border-color: transparent;
      color: #212529;
    }
  }
}
